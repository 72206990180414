import * as actions from './constants'

export const getProfile = () => ({
  type: actions.APP_GET_PROFILE_REQUEST,
})

export const getAllPatientList = () => ({
  type: actions.APP_GET_ALL_PATIENT_LIST_REQUEST,
})

export const addPatientAction = (values) => ({
  type: actions.APP_ADD_PATIENT_REQUEST,
  values
})

export const getOnePatietnPulses = (values) => ({
  type: actions.APP_GET_ONE_PATIENT_ALL_PULSE_REQUEST,
  values
})
