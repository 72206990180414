import * as actions from './constants'

const initialState = {
  isLoading: false,
  profile: {},
  patientList: {},
  addPatient: {},
  onePatientPulse: {},
  errors: {
    profile: null,
    addPatient: null
  }
}

export const AppReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.APP_GET_ONE_PATIENT_ALL_PULSE_SUCCESS:
      return {
        ...state,
        onePatientPulse: action.payload,
    }
    case actions.APP_ADD_PATIENT_ERROR:
      return {
        ...state,
         errors: { addPatient: action.error }
      }
    case actions.APP_ADD_PATIENT_REQUEST:
      return {
        ...state,
        addPatient: null,
        errors: { addPatient: null }
      }
    case actions.APP_ADD_PATIENT_SUCCESS:
      return {
        ...state,
        addPatient: action.payload,
        errors: { addPatient: null }
      }
    case actions.APP_GET_ALL_PATIENT_LIST_SUCCESS:
      return {
        ...state,
        patientList: action.payload
      }
    case actions.APP_GET_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload,
        errors: { Profile: null }
      }
    case actions.APP_GET_PROFILE_ERROR:
      return {
        ...state,
        errors: { Profile: action.error }
      }
    case actions.APP_CHANGE_LOADING_STATE:
      return { ...state,
        isLoading: action.payload };
    case actions.APP_CLEAR_STATE:
      return initialState;
    default:
      return state
  }
}
