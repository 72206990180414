import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addPatientAction, getAllPatientList } from '../../redux/app/actions';

import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Divider, TextField, Typography } from '@mui/material';
import DescriptionAlerts from '../../components/descriptionAlerts';
import { useNavigate } from 'react-router-dom';


const columns = [
  {
    field: 'name',
    headerName: 'Full Name',
    width: 180,
    editable: false,
  },
  {
    field: 'rfid',
    headerName: 'Rfid',
    width: 160,
    editable: false,
  },
  {
    field: 'passport',
    headerName: 'passport',
    width: 120,
    editable: false,
  },
  {
    field: 'psc',
    headerName: 'PSC ( id - card )',
    width: 150,
    editable: false,
  },
  {
    field: 'address',
    headerName: 'address',
    width: 180,
    editable: false,
  },
  {
    field: 'comments',
    headerName: 'comments',
    width: 200,
    editable: false,
  },
  { field: 'id', headerName: 'ID', width: 110 },
];

export default function Patient () {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const patientList = useSelector((state) => state.App.patientList);
  const addPatient = useSelector((state) => state.App.addPatient);
  const errorAddPatient = useSelector((state) => state.App.errors.addPatient);
  const [rows, setRows] = React.useState([]);
  const [error, setError] = React.useState({
    severity: '',
    message: ''
  });

  React.useEffect(() => {
    dispatch(getAllPatientList())
  }, [dispatch])

  React.useEffect(() => {
    if(patientList?.length){
      const t = patientList?.map((v) => ({
        name: v?.fullName,
        rfid: v?.rfid,
        passport: v?.passport,
        psc: v?.psc,
        address: v?.address,
        id: v?._id,
        comments: v?.comments
      }))
      setRows(t)
    }
  }, [patientList])

  const handleSubmit = (event) => {
     setError({
          severity: '',
          message: ''
      })

    event.preventDefault();
    const data = new FormData(event.currentTarget);

    dispatch(addPatientAction({
      rfid: Number(data.get('rfid')),
      fullName: data.get('fullName'),
      passport: data.get('passport'),
      psc: data.get('psc'),
      address: data.get('address'),
      comments: data.get('comments')
    }))
  };

  React.useEffect(() => {
    if(errorAddPatient){
      setError({
          severity: 'error',
          message: `${errorAddPatient}`
      })
    }
  }, [errorAddPatient])


  React.useEffect(() => {
    if(addPatient?.rfid){
        setError({
          severity: 'success',
          message: `patient frid ( ${addPatient?.rfid} ) added.`
      })

    }

  }, [addPatient?.rfid])

  return (
    <Box sx={{ height: 600, width: '100%' }}>
       <Typography
        component="h1"
        variant="h6"
        color="inherit"
        noWrap
        sx={{ flexGrow: 1 }}
      >
        Patient control.
      </Typography>
      <Divider sx={{ mt: 4, mb: 4 }}/>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection
        disableRowSelectionOnClick
      />

       <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
         <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            Add patient...
          </Typography>
          <Divider sx={{ mt: 4, mb: 4 }}/>
            <TextField
              margin="normal"
              required
              fullWidth
              type='number'
              id="rfid"
              label="rfid number"
              name="rfid"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="fullName"
              label="fullName"
              id="fullName"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="passport"
              label="passport"
              id="passport"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="psc"
              label="psc"
              id="psc"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="address"
              label="address"
              id="address"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="comments"
              label="comments"
              id="comments"
            />
            <DescriptionAlerts severity={error?.severity} message={error?.message} />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
    </Box>
  );
}



