import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPatientList, getOnePatietnPulses } from '../../redux/app/actions';
import { Box, Container, Divider, Paper, Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import moment from 'moment';

export default function Pulse () {
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.Auth.accessToken);
  const patientList = useSelector((state) => state.App.patientList);
  const onePatientPulse = useSelector((state) => state.App.onePatientPulse);
  const [selectData, setSelectData] = React.useState('');

  const handleChange = (event) => {
    setSelectData(event.target.value);
  };

  React.useEffect(() => {
    if(accessToken){
      dispatch(getAllPatientList());
    }
  }, [dispatch, accessToken])

   React.useEffect(() => {
    if(selectData){
      dispatch(getOnePatietnPulses({
        id: selectData
      }))
    }
  }, [dispatch, selectData])

  React.useEffect(() => {
    let t = []
    if(onePatientPulse?.length){

        onePatientPulse?.map((v) => {
          return t.push(moment(v?.createdAt).format('MMMM Do YYYY, h:mm:ss a'))
        })
      }
  }, [onePatientPulse])
  return (
    <Box sx={{ height: 600, minWidth: 1200 }}>
       <Typography
        component="h1"
        variant="h6"
        color="inherit"
        noWrap
        sx={{ flexGrow: 1 }}
      >
        Pulse control.
      </Typography>
      <Divider sx={{ mt: 4, mb: 4 }}/>

        <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
          <InputLabel id="demo-select-small-label">Select Patient</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={selectData}
            label="Select Patient"
            onChange={handleChange}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {
                patientList?.length ?
                  patientList?.map((v) => (
                    <MenuItem key={v?._id} value={v?.rfid}>{v?.fullName}</MenuItem>
                  ))
                : null
            }
          </Select>
        </FormControl>

        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          {
            onePatientPulse?.length ?
              <Paper
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  color: 'black',
                  padding: 9
                }}
              >
              <Typography
                  component="h1"
                  variant="h6"
                  color="inherit"
                  noWrap
                  sx={{ flexGrow: 1 }}
              >
                  count: {onePatientPulse?.length}
              </Typography>
              <Divider sx={{ mt: 4, mb: 4 }}/>
              <table>
                <tbody>
                  <tr style={{ backgroundColor: '#04AA6D', height: 40, textAlign: 'center'  }}>
                    <td>Created at:</td>
                    <td>Pulse:</td>
                    <td>Dia:</td>
                    <td>Sys:</td>
                    <td>Rfid:</td>
                    <td>Device-Id:</td>
                  </tr>
                  {
                    onePatientPulse?.map((v) => (
                      <tr key={v?._id} style={{ backgroundColor: '#f2f2f2', height: 40, textAlign: 'center'  }}>
                        <td>{moment(v?.createdAt)?.format('MMMM Do YYYY, h:mm:ss a')}</td>
                        <td>{v?.pulse}</td>
                        <td>{v?.dia}</td>
                        <td>{v?.sys}</td>
                        <td>{v?.rfid}</td>
                        <td>{v?.deviceId}</td>
                      </tr>
                    ))
                  }
                  </tbody>
                </table>
              </Paper>
            :
             <Typography
                  component="h1"
                  variant="h6"
                  color="inherit"
                  noWrap
                  sx={{ flexGrow: 1 }}
              >
                  No collected data.
              </Typography>
          }
        </Container>
      </Box>
  );
}