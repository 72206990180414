import * as React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function DescriptionAlerts({ severity, action, message }) {
  // severity = success || info || warning || error

  const modStr = severity[0]?.toUpperCase() + severity?.slice(1);

  return (
    <Stack sx={{ width: '100%' }} spacing={2}>
      {
        severity ?
            <Alert variant="filled" severity={severity}
              action={ action ?
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => { }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton> : null}
            >
        <AlertTitle>{modStr}</AlertTitle>
        {message}
      </Alert> : null
      }
    </Stack>
  );
}