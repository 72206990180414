import * as actions from './constants'

export const login = (values) => ({
  type: actions.AUTH_LOGIN_REQUEST,
  values
})

export const setUser = (user, token) => ({
  type: actions.AUTH_USER,
  user,
  token
})

export const logout = () => ({
  type: actions.AUTH_LOGOUT
})