import {
  all, takeLatest, put, call
} from 'redux-saga/effects'

import {
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_ERROR,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT,
} from './constants'
import { request, addTokenToHttp } from '../../utils/http'
import StorageUtils from '../../utils/storage'
import { APP_CLEAR_STATE } from '../app/constants'


function sendLogin ({ values }) {
  return request.post('/user/login/', values)
}

function * handleLogin ({ values }) {
  try {
    const { status, data } = yield call(sendLogin, { values })
    if (status === 200) {
      yield put({
        type: AUTH_LOGIN_SUCCESS,
        accessToken: data.token
      })

      StorageUtils.setAccessToken(data.token)
      addTokenToHttp(data.token)
      // yield put(getProfile())
    } else {
      yield put({
        type: AUTH_LOGIN_ERROR,
        error: 'Unknown Error'
      })
    }
  } catch (error) {
    yield put({
      type: AUTH_LOGIN_ERROR,
      error: error.response.data
    })
    // console.log('Error Login', error.response.data)
  }
}


function * handleLogout () {
  try {
    StorageUtils.removeAccessToken()
    StorageUtils.removeUser()
    addTokenToHttp('')
    yield put({
      type: APP_CLEAR_STATE
    })
  } catch (error) {
    console.log('error :>> ', error)
  }
}

export default all([
  takeLatest(AUTH_LOGIN_REQUEST, handleLogin),
  takeLatest(AUTH_LOGOUT, handleLogout),
])
