import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DescriptionAlerts from '../../components/descriptionAlerts';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../redux/auth/actions';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="/">
        Health
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function Login() {
  const loginError = useSelector((state) => state.Auth.errors.SignIn);
  const dispatch = useDispatch()
  const [error, setError] = React.useState({
    severity: '',
    message: ''
  })
  const handleSubmit = (event) => {
    setError({ severity: '', message: '' })

    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if(!data.get('email') || !data.get('password')){
        setError({ severity: 'error', message: 'email or password cannot be empty.' })
    }else{
      dispatch(login({
        email: data.get('email'),
        password: data.get('password')
      }))
    }

  };

  React.useEffect(() => {
    if(loginError?.message?.length > 0){
      setError({ severity: 'error', message: loginError?.message })
    }
  }, [loginError?.message, loginError?.message.length])

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h4">
            Help to Health.
          </Typography>
          <Typography component="h5" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />

            <DescriptionAlerts severity={error?.severity} message={error?.message} />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}