export const APP_CHANGE_LOADING_STATE = 'APP/CHANGE_LOADING_STATE'
export const APP_CLEAR_STATE = 'APP/CLEAR_STATE'

export const APP_GET_PROFILE_REQUEST = 'APP/GET_PROFILE/REQUEST'
export const APP_GET_PROFILE_SUCCESS = 'APP/GET_PROFILE/SUCCESS'
export const APP_GET_PROFILE_ERROR = 'APP/GET_PROFILE/ERROR'

export const APP_GET_ALL_PATIENT_LIST_REQUEST = 'APP/GET_ALL_PATIENT_LIST/REQUEST'
export const APP_GET_ALL_PATIENT_LIST_SUCCESS = 'APP/GET_ALL_PATIENT_LIST/SUCCESS'
export const APP_GET_ALL_PATIENT_LIST_ERROR = 'APP/GET_ALL_PATIENT_LIST/ERROR'

export const APP_ADD_PATIENT_REQUEST = 'APP/ADD_PATIENT/REQUEST'
export const APP_ADD_PATIENT_SUCCESS = 'APP/ADD_PATIENT/SUCCESS'
export const APP_ADD_PATIENT_ERROR = 'APP/ADD_PATIENT/ERROR'

export const APP_GET_ONE_PATIENT_ALL_PULSE_REQUEST = 'APP/GET_ONE_PATIENT_ALL_PULSE/REQUEST'
export const APP_GET_ONE_PATIENT_ALL_PULSE_SUCCESS = 'APP/GET_ONE_PATIENT_ALL_PULSE/SUCCESS'
export const APP_GET_ONE_PATIENT_ALL_PULSE_ERROR = 'APP/GET_ONE_PATIENT_ALL_PULSE/ERROR'