import React, { useEffect } from "react";
import { Provider } from "react-redux";

import { setupHttpConfig } from "./utils/http";
import Navigation from "./Navigation";
import { store } from "../src/redux/store";

function App() {

  useEffect(() => {
    setupHttpConfig();
  }, []);

  return (
      <Provider store={store}>
        <Navigation />
      </Provider>
  );
}
export default App;
