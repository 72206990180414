import * as actions from './constants'

const initialState = {
  user: null,
  accessToken: null,
  profile: {},
  errors: {
    SignIn: null,
  }
}

export const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        accessToken: action.accessToken,
        errors: { SignIn: null }
      }
    case actions.AUTH_LOGIN_REQUEST:
      return {
        ...state,
        errors: { SignIn: null }
      }
    case actions.AUTH_LOGIN_ERROR:
      return {
        ...state,
        errors: { SignIn: action.error }
      }
    case actions.AUTH_USER:
      return {
        ...state,
        user: action.user,
        accessToken: action.token
      }
    case actions.AUTH_LOGOUT:
      return initialState
    default:
      return state
  }
}
