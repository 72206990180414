import React, { useEffect, useRef } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { logout, setUser } from "./redux/auth/actions";
import StorageUtils from "./utils/storage";
import { addTokenToHttp } from "./utils/http";
import { getProfile } from "./redux/app/actions";

import "./styles.css";
import Login from "./screens/login";

import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LogoutIcon from '@mui/icons-material/Logout';
import { mainListItems } from './components/listItems';
import Patient from "./screens/patient";
import Doctors from "./screens/doctors";
import Pulse from "./screens/pulse";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const defaultTheme = createTheme();

const Navigation = () => {
  const token = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state.Auth.accessToken);
  const profile = useSelector((state) => state.App.profile);
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    (async () => {
      token.current = await StorageUtils.getAccessToken();
      dispatch(setUser({}, token?.current));
      addTokenToHttp(token?.current);
      if (accessToken) {
         dispatch(getProfile())
      }
    })();
  }, [accessToken, dispatch]);

  return (
        <ThemeProvider theme={defaultTheme}>
          <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {
                accessToken ?
                          <AppBar position="absolute" open={open}>
                    <Toolbar
                      sx={{
                        pr: '24px', // keep right padding when drawer closed
                      }}
                    >
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                          marginRight: '36px',
                          ...(open && { display: 'none' }),
                        }}
                      >
                        <MenuIcon />
                      </IconButton>
                      <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                      >
                        Help to Health Dashboard ( { profile?.fullName } )
                      </Typography>
                      {/* <IconButton color="inherit">
                        <Badge badgeContent={4} color="secondary">
                          <NotificationsIcon />
                        </Badge>
                      </IconButton> */}

                      <IconButton onClick={() =>{
                         dispatch(logout());
                         navigate('/');
                         }} color="inherit">
                        <Badge color="secondary">
                          <LogoutIcon />
                        </Badge>
                      </IconButton>

                    </Toolbar>
                  </AppBar>
                : null
            }
            {
                accessToken  ?
                  <Drawer variant="permanent" open={open}>
                  <Toolbar
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      px: [1],
                    }}
                  >
                    <IconButton onClick={toggleDrawer}>
                      <ChevronLeftIcon />
                    </IconButton>
                  </Toolbar>
                  <Divider />
                  <List component="nav">
                    {mainListItems}
                  </List>
                </Drawer>
                : null
            }
            <Box
              component="main"
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
              }}
            >
              <Toolbar />
              <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                <Routes>
                  {
                    accessToken ?
                      <>
                        <Route path="/" element={<Patient />} />
                        <Route path="/doctors" element={<Doctors />} />
                        <Route path="/pulse" element={<Pulse />} />
                      </>
                      :
                      <>
                       <Route path="/" element={<Login />} />
                      </>
                  }
                </Routes>
              </Container>
            </Box>
          </Box>
        </ThemeProvider>

  );
};

export default Navigation;
