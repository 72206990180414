import axios from 'axios'
export const BASE_URL = 'https://api.hth.am/api/'
export const request = axios.create({
  headers: {

  }
})
export function setupHttpConfig () {
  request.defaults.baseURL = BASE_URL
  request.defaults.timeout = 720000
  request.defaults.withCredentials = false

  axios.defaults.baseURL = BASE_URL
  axios.defaults.timeout = 720000
  axios.defaults.withCredentials = false
  axios.defaults.headers['Content-Type'] = 'application/json'
}

export function addTokenToHttp (token) {
  return new Promise((resolve) => {
    if (token) {
      request.defaults.headers.authorization = token
      axios.defaults.headers.authorization = token
      resolve()
    } else {
      request.defaults.headers.Authorization = ''
      axios.defaults.headers.Authorization = ''
      resolve()
    }
  })
}
