import * as React from 'react';
import Container from '@mui/material/Container';

export default function Doctors () {

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      Doctors
    </Container>
  );
}