import { all, takeLatest } from 'redux-saga/effects'
import * as actions from './constants'
import { request } from '../../utils/http'
import { sagasRunner } from '../../utils/redux'

function getProfile () {
  return request.get('/user')
}

function handleGetProfile () {
  return sagasRunner({
    successType: actions.APP_GET_PROFILE_SUCCESS,
    errorType: actions.APP_GET_PROFILE_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: 'Unable to get profile data.',
    callFunc: getProfile,
    alertError: false,
    isProfile: true
  })
}

function getAllPatientList () {
  return request.get('/patient/all/list')
}

function handleAllPAtientListGet () {
  return sagasRunner({
    successType: actions.APP_GET_ALL_PATIENT_LIST_SUCCESS,
    errorType: actions.APP_GET_ALL_PATIENT_LIST_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: 'Unable to get profile data.',
    callFunc: getAllPatientList,
    alertError: false,
    isProfile: true
  })
}

function AddPatient ({values}) {
  return request.post('/patient', values)
}

function handleAddPatient ({values}) {
  return sagasRunner({
    successType: actions.APP_ADD_PATIENT_SUCCESS,
    errorType: actions.APP_ADD_PATIENT_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: 'Unable to get profile data.',
    callFunc: AddPatient,
    updateType: actions.APP_GET_ALL_PATIENT_LIST_REQUEST,
    callData: { values },
    alertError: false,
    isProfile: true
  })
}

function getOnePatientAllPulses ({values}) {
  return request.get(`/patient/${values?.id}`)
}

function handleGetOnePatientAllPulses ({values}) {
  return sagasRunner({
    successType: actions.APP_GET_ONE_PATIENT_ALL_PULSE_SUCCESS,
    errorType: actions.APP_GET_ONE_PATIENT_ALL_PULSE_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: 'Unable to get profile data.',
    callFunc: getOnePatientAllPulses,
    callData: { values },
    alertError: false,
    isProfile: true
  })
}



export default all([
  takeLatest(actions.APP_GET_PROFILE_REQUEST, handleGetProfile),
  takeLatest(actions.APP_GET_ALL_PATIENT_LIST_REQUEST, handleAllPAtientListGet),
  takeLatest(actions.APP_ADD_PATIENT_REQUEST, handleAddPatient),
  takeLatest(actions.APP_GET_ONE_PATIENT_ALL_PULSE_REQUEST, handleGetOnePatientAllPulses),

])
